import React from "react";
import PropTypes from "prop-types";
import { Loader } from "@googlemaps/js-api-loader";
import config from "../../../content/meta/config";
import MapIcon from "!svg-react-loader!../../images/svg-icons/map.svg?name=map";

class MapPreview extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      map: undefined,
      locationMakers: []
    };
  }

  startAnimation(index) {
    this.state.locationMakers[index].maker.setAnimation(google.maps.Animation.BOUNCE);
  }

  stopAnimation(index) {
    this.state.locationMakers[index].maker.setAnimation(null);
  }

  loadMap() {
    const loader = new Loader({
      apiKey: config.mapAPIKey,
      version: "weekly"
    });
    loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: config.mapLatLng,
        zoom: 14
      });
      // const svgMarker = {
      //   path:
      //     "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      //   fillColor: "orange",
      //   fillOpacity: 0.9,
      //   strokeWeight: 0,
      //   rotation: 0,
      //   scale: 2,
      //   anchor: new google.maps.Point(15, 20)
      // };
      new google.maps.Marker({
        position: config.mapLatLng,
        map,
        icon: {
          url: "/icons/pin.svg",
          anchor: new google.maps.Point(15, 20),
          scaledSize: new google.maps.Size(48, 48), // size
          origin: new google.maps.Point(0, 0) // origin
        }
      });

      const locationMakers = [];
      config.nearLocation.map((location, index) => {
        locationMakers.push({
          ...location,
          maker: new google.maps.Marker({
            position: location.latLng,
            map,
            icon: {
              url: "/icons/flag.svg",
              anchor: new google.maps.Point(8, 35),
              scaledSize: new google.maps.Size(36, 36), // size
              origin: new google.maps.Point(0, 0) // origin
            }
            // animation: google.maps.Animation.BOUNCE
            // icon: svgMarker
          })
        });
      });

      this.setState({
        map,
        locationMakers
      });
    });
  }

  componentDidMount() {
    if (this.mapRef.current) {
      const OPTIONS = {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 0
      };
      const observer = new IntersectionObserver((entries, o) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.setState({ isVisible: true });
            this.loadMap();
            o.unobserve(this.mapRef.current);
          }
        });
      }, OPTIONS);
      observer.observe(this.mapRef.current);
    }
  }

  render() {
    const { theme } = this.props;

    return (
      <React.Fragment>
        <section className="wrapper" ref={this.mapRef}>
          <div className="map">
            <div id="map" style={{ height: "100%", width: "100%" }} />
          </div>
          <div className="location">
            <div className="title">Nearby</div>
            <ul>
              {config.nearLocation.map((location, index) => (
                <li key={location.label.th}>
                  <span className="icon">
                    <MapIcon />
                  </span>
                  <span
                    className="label"
                    onMouseOver={() => this.startAnimation(index)}
                    onMouseLeave={() => this.stopAnimation(index)}
                  >
                    {location.label.th}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </section>

        {/* --- STYLES --- */}
        <style jsx>{`
          .wrapper {
            background: ${theme.color.neutral.gray.a};
            width: 100%;
            height: 640px;
            display: flex;

            .map {
              width: 100%;
              height: 100%;
              background: #ffffff;
            }

            .location {
              width: 45%;
              height: 100%;
              padding: 10px 60px;
              display: none;

              .title {
                font-family: ${theme.font.family.simpleHeader};
                font-size: ${theme.font.size.xxl};
                color: ${theme.color.brand.primary};
                font-weight: normal;
                text-transform: uppercase;
                padding: 20px 0;
              }
            }

            ul {
              list-style: none;
              font-family: ${theme.font.family.thaiText};
              line-height: 3em;
              font-weight: 500;
              font-size: ${theme.font.size.s};

              li {
                display: flex;
              }

              .label {
                cursor: pointer;
              }

              .icon {
                width: 3em;
                height: 3em;

                :global(svg) {
                  width: 3em;
                }
              }
            }
          }

          @from-width desktop {
            .wrapper {
              .map {
                width: 55%;
              }

              .location {
                display: block;
              }
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

MapPreview.propTypes = {
  theme: PropTypes.object.isRequired
};

export default MapPreview;
