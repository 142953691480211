import React from "react";
import PropTypes from "prop-types";

const ContactHero = props => {
  const { backgrounds, theme } = props;

  return (
    <React.Fragment>
      <section className="hero">
        <div className="hero-inner">
          <h1 className="line-1">Contact Us</h1>
        </div>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .hero {
          background: ${theme.hero.background};
          background-image: url(${backgrounds.desktop});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-attachment: fixed;
          color: ${theme.text.color.primary.inverse};
          height: 400px;
          min-height: 100px;
          max-height: 50vh;
          padding: 60px;
          margin-top: ${theme.header.height.mobile};
        }

        .hero-inner {
          width: 100%;
          height: 100%;
          max-width: 1000px;
          margin: auto;
          align-items: center;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          position: relative;
        }

        h1 {
          text-align: center;
          font-size: ${`calc(${theme.hero.h1.size} - 0.3em)`};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h1.lineHeight};
          font-family: ${theme.font.family.hero};
          text-transform: uppercase;
          letter-spacing: 0.1px;
          font-weight: 500;

          position: relative;
          z-index: 1;
        }

        @from-width tablet {
          .hero {
            background-image: url(${backgrounds.desktop});
          }

          h1 {
            max-width: 90%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
          }
        }

        @from-width desktop {
          .hero {
            background-image: url(${backgrounds.desktop});
            margin-top: ${theme.header.height.default};
          }

          h1 {
            max-width: 80%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};

            :before {
              content: "";
              z-index: -1;
              background: ${theme.color.brand.primaryBackground};
              position: absolute;
              top: 60%;
              left: -${theme.space.xs};
              right: -${theme.space.xs};
              bottom: -${theme.space.xs};
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

ContactHero.propTypes = {
  backgrounds: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isHideButton: PropTypes.bool,
  text1: PropTypes.string,
  text2: PropTypes.string
};

export default ContactHero;
