import React from "react";
import PropTypes from "prop-types";

const Button = props => {
  const { theme, children, onClick, background } = props;

  return (
    <React.Fragment>
      <span className="button" onClick={onClick}>
        {children}
      </span>

      {/* --- STYLES --- */}
      <style jsx>{`
        .button {
          color: ${theme.color.neutral.white};
          font-family: ${theme.font.family.thaiText};
          background: ${background || theme.color.brand.primary};
          padding: 0.8em 1.8em;
          display: inline-box;
          border-radius: 0px;
          font-weight: 600;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
};

Button.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  background: PropTypes.string
};

export default Button;
