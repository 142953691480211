import React from "react";
import PropTypes from "prop-types";
import { Loader } from "@googlemaps/js-api-loader";
import config from "../../../content/meta/config";

class Contact extends React.Component {
  state = {
    map: undefined,
    locationMakers: []
  };

  startAnimation(index) {
    this.state.locationMakers[index].maker.setAnimation(google.maps.Animation.BOUNCE);
  }

  stopAnimation(index) {
    this.state.locationMakers[index].maker.setAnimation(null);
  }

  componentDidMount() {
    const loader = new Loader({
      apiKey: config.mapAPIKey,
      version: "weekly"
    });
    loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: config.mapLatLng,
        zoom: 14
      });
      // const svgMarker = {
      //   path:
      //     "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      //   fillColor: "orange",
      //   fillOpacity: 0.9,
      //   strokeWeight: 0,
      //   rotation: 0,
      //   scale: 2,
      //   anchor: new google.maps.Point(15, 20)
      // };
      new google.maps.Marker({
        position: config.mapLatLng,
        map,
        icon: {
          url: "/icons/pin.svg",
          anchor: new google.maps.Point(15, 20),
          scaledSize: new google.maps.Size(48, 48), // size
          origin: new google.maps.Point(0, 0) // origin
        }
      });

      const locationMakers = [];
      config.nearLocation.map((location, index) => {
        locationMakers.push({
          ...location,
          maker: new google.maps.Marker({
            position: location.latLng,
            map,
            icon: {
              url: "/icons/flag.svg",
              anchor: new google.maps.Point(8, 35),
              scaledSize: new google.maps.Size(36, 36), // size
              origin: new google.maps.Point(0, 0) // origin
            }
            // animation: google.maps.Animation.BOUNCE
            // icon: svgMarker
          })
        });
      });

      this.setState({
        map,
        locationMakers
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <div id="map" />
        <style jsx>{`
          #map {
            height: 100%;
            width: 100%;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

Contact.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Contact;
