import React from "react";
import PropTypes from "prop-types";
import config from "../../../content/meta/config";

import Button from "../Button";

const Hero = props => {
  const { backgrounds, theme } = props;

  return (
    <React.Fragment>
      <section className="hero">
        <div className="hero-inner">
          <h1 className="line-1">Peerapat Residence</h1>
          <h1 className="line-2">หอพักพีรพัชร์ พหล 55</h1>

          <div className="button-row">
            <a href={config.contact.line.url} target="_blank" rel="noopener noreferrer">
              <Button theme={theme} background={theme.color.special.line}>
                แอดไลน์ติดต่อจองห้องพัก
              </Button>
            </a>
          </div>
        </div>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .hero {
          background: ${theme.hero.background};
          background-image: url(${backgrounds.desktop});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-attachment: fixed;
          color: ${theme.text.color.primary.inverse};
          min-height: 80vh;
          height: 80px;
          padding: 60px;
          margin-top: ${theme.header.height.mobile};
        }

        .hero-inner {
          width: 100%;
          height: 100%;
          max-width: 800px;
          margin: auto;
          align-items: center;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          position: relative;
        }

        h1 {
          text-align: center;
          font-size: ${`calc(${theme.hero.h1.size})`};
          margin: ${theme.space.stack.l};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h1.lineHeight};
          font-family: ${theme.font.family.thaiText};
          text-transform: uppercase;
          letter-spacing: 0.1px;
          font-weight: 500;

          position: relative;
          z-index: 1;

          &.line-1 {
            font-family: ${theme.font.family.hero};
          }
          &.line-2 {
            font-size: 2em;
          }
        }

        h2 {
          line-height: ${theme.hero.h1.lineHeight};
          font-family: ${theme.font.family.subHero};
          color: ${theme.hero.h2.color};
          font-size: ${theme.hero.h2.size};
          text-align: center;
          font-weight: 400;
        }

        .button-row {
          position: absolute;
          bottom: 0;
        }

        @keyframes buttonIconMove {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
          100% {
            transform: translateY(0);
          }
        }

        @from-width tablet {
          .hero {
            background-image: url(${backgrounds.desktop});
          }

          h1 {
            max-width: 90%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
          }

          .hero .button-row :global(.button) {
            font-size: large;
          }
        }

        @from-width desktop {
          .hero {
            background-image: url(${backgrounds.desktop});
            margin-top: ${theme.header.height.default};
          }

          h1 {
            max-width: 80%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};

            :before {
              content: "";
              z-index: -1;
              background: ${theme.color.brand.primaryBackground};
              position: absolute;
              top: 60%;
              left: -${theme.space.xs};
              right: -${theme.space.xs};
              bottom: -${theme.space.xs};
            }

            &.line-1 {
              align-self: flex-start;
            }

            &.line-2 {
              align-self: flex-end;
              margin-left: 200px;
              font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
            }
          }

          button {
            font-size: ${theme.font.size.xxl};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Hero.propTypes = {
  scrollToContent: PropTypes.func,
  backgrounds: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Hero;
